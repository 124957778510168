<template>
  <v-row justify="center">
    <v-dialog :value="show" @click:outside="$emit('close')" max-width="960">
      <v-card>
        <v-card-title>
          <span class="text-h5 font-weight-bold">{{
            title
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container class="black--text">
            <slot name="imagem">
            </slot>
            <slot name="body"></slot>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="$emit('close')"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    show: Boolean,
    title: String,
    titleColor: String,
  },
  data() {
    return {
      dialog: false,
    }
  },
}
</script>

<style></style>
