<template>
  <v-container>
    <DialogCard
      v-if="dialogVer"
      :show="dialogVer"
      :title="noticiaVer.title"
      :img="noticiaVer.imagem"
      @close="
        dialogVer = false
        noticiaVer = {}
      "
    >
      <template v-slot:body>
        <div v-if="noticiaVer.tipo === 2 || noticiaVer.tipo === 6" v-html="titleC"></div>
      </template>
      <template v-slot:imagem>
        <h2 v-if="noticiaVer.tipo === 1" class="mb-5">SLIDE SHOW</h2>
        <h2 v-if="noticiaVer.tipo === 3" class="mb-5">GALERIA VIDEOS</h2>
        <h2 v-if="noticiaVer.tipo === 4" class="mb-5">GALERIA AVISOS</h2>

        <div class="text-center mb-3">
          <iframe
            v-if="noticiaVer.tipo === 3"
            width="820"
            height="400"
            :src="noticiaVer.videoLink"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          >
          </iframe>

          <a
            v-if="noticiaVer.tipo === 5"
            target="_blank"
            :href="noticiaVer.anexo"
          >
            <img :src="noticiaVer.imagem" height="337"
          /></a>


          <div
            v-if="noticiaVer.tipo === 1"
            class="container-imagem-slide"
          >
            <img :src="noticiaVer.imagem" />
          </div>

          <img
            v-if="
              noticiaVer.tipo === 2 ||
              noticiaVer.tipo === 4 ||
              noticiaVer.tipo === 6
            "
            height="337"
            :src="noticiaVer.imagem"
          />

        </div>
      </template>
    </DialogCard>

    <div class="text-center">
      <v-dialog v-model="dialogEditar" width="900">
        <v-card class="overflow-hidden">
          <v-toolbar flat>
            <v-toolbar-title class="font-weight-light">
              Editar Publicação
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <EditarNoticia
              :formatarCampo="dialogEditar"
              :noticia-editar="noticiaVer"
              @atualizar="atualizarPagina()"
            ></EditarNoticia>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div class="text-center">
      <v-dialog v-model="AdicionarPublicacao" width="900">
        <v-card class="overflow-hidden">
          <v-toolbar flat>
            <v-toolbar-title class="font-weight-light">
              Adicionar Publicação
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon @click="AdicionarPublicacao = false">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text>
            <CadastroNoticia
              :formatarCampo="AdicionarPublicacao"
              @atualizar="atualizarPagina()"
            ></CadastroNoticia>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div class="text-center">
      <v-dialog v-model="dialogDelete" width="600">
        <v-card class="overflow-hidden">
          <v-toolbar flat>
            <v-toolbar-title class="ml-2 pt-5">
              <span class="text-title-4"
                >As informações, imagens e links serão excluidos do portal
                <br />
                CAAMA.ORG.BR</span
              >
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text class="mt-5">
            <span class="text-subtitle-1">
              Deseja continuar com essa operação?</span
            >
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              @click="
                dialogDelete = false
                noticia = {}
                receberNoticias()
              "
              color="red darken-1"
              text
              >Cancelar</v-btn
            >
            <v-btn
              @click="
                deletePublicação()
                receberNoticias()
              "
              color="blue darken-1"
              text
              >Confirmar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="text-center">
      <DialogConfirmation
        :width="500"
        :show="dialogFiltro"
        :key="keyDialogFiltro"
        :tag="'add'"
        @close="fecharFiltro()"
        @confirm="filtoNoticia()"
        title="Aplicar Filtros"
      >
        <template v-slot:body>
          <v-row>
            <v-col cols="12" md="12">
              <v-autocomplete
                clearable
                hide-details="auto"
                outlined
                v-model="filtro.tipo"
                item-text="descricao"
                item-value="id"
                label="Filtrar pelo tipo"
                :items="tiposNoticias"
              ></v-autocomplete>
            </v-col>
            </v-row>
        </template>
      </DialogConfirmation>
    </div>

    <CrudTable
      :headers="headers"
      :items="items"
      :pagination="pagination"
      :loading="loading"
      @actualPage="page"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <ButtonMain
            @click.native="AdicionarPublicacao = true"
            :primary="true"
            text="Adicionar Publicação"
          />
          <v-spacer></v-spacer>

          <ButtonMain
            v-if="filtroAtivo"
            class="mx-1"
            @click.native="limparFiltro()"
            :warning="true"
            text="Limpar Filtro"
          />

          <ButtonMain
            :primary="true"
            text="Filtrar"
            @click.native="abrirFiltro()"
          />
        </v-toolbar>
      </template>
      <template v-slot:[`item.acoes`]="{ item }">
        <v-row>
          <TooltipMain top text="Editar Publicação">
            <ButtonIcon
              class="mx-1"
              :warning="true"
              @click.native="editPublicação(item)"
              icon="pencil"
            />
          </TooltipMain>
          <TooltipMain top text="Excluir Publicação">
            <ButtonIcon
              class="mx-1"
              :secondary="true"
              @click.native="
                noticia = item
                dialogDelete = true
              "
              icon="close-circle"
            />
          </TooltipMain>
          <TooltipMain top text="Ver Publicação">
            <ButtonIcon
              class="mx-1"
              :primary="true"
              @click.native="verPublicação(item)"
              icon="eye"
            />
          </TooltipMain>
        </v-row>
      </template>
    </CrudTable>
  </v-container>
</template>

<script>
import EditarNoticia from './EditarNoticia'
import DialogCard from '@/components/molecules/dialogs/DialogCard.vue'
import CadastroNoticia from './CadastroNoticia'
import ButtonIcon from '../../components/atoms/buttons/ButtonIcon'
import TooltipMain from '../../components/atoms/TooltipMain'
import TitleH2 from '../../components/atoms/title/TitleH2'
import api from '@/services/api'
import CrudTable from '@/components/molecules/tables/CrudTable.vue'
import ButtonMain from '@/components/atoms/buttons/ButtonMain.vue'
import DialogConfirmation from '@/components/molecules/dialogs/DialogConfirmation.vue'

export default {
  components: {
    EditarNoticia,
    CadastroNoticia,
    ButtonIcon,
    TooltipMain,
    TitleH2,
    CrudTable,
    ButtonMain,
    DialogConfirmation,
    DialogCard
  },

  data() {
    return {
      keyDialogFiltro: 0,
      dialogEditar: false,
      filtroAtivo: false,
      noticiaVer: {},
      AdicionarPublicacao: false,
      noticia: {},
      dialogVer: false,
      dialogDelete: false,
      dialogFiltro: false,
      loading: false,
      pagination: {
        page: 0,
        perPage: 10,
        total: 100
      },
      headers: [
        { text: 'Titulo', value: 'titulo' },
        { text: 'Tipo', value: 'tipoNoticia.descricao' },
        { text: 'Data de cadastro', value: 'dataCadastro' },
        { text: 'Inicio Publicação', value: 'inicioPublicacao' },
        { text: 'Fim Publicação', value: 'fimPublicacao' },
        { text: 'Ações', width: 200, align: 'start', value: 'acoes' }
      ],
      items: [],
      filtro: {
        tipo: '',
        exibicao: ''
      },
      tipoNoticia: {},
      tiposNoticias: [],
    }
  },

  computed: {
    titleC() {
      return `${this.noticiaVer.content}`
    }
  },

  methods: {
    fechar() {
      this.dialogEditar = false
    },

    atualizarPagina() {
      this.receberNoticias();
    },

    verPublicação(item) {
      api.getNoticiaId(item.id).then(({ data }) => {
        this.noticiaVer = {
          title: data.titulo,
          imagem: data.caminhoArquivo,
          content: data.texto,
          videoLink: this.loadURL(data.link),
          tipo: data.tipoNoticia.id,
          inicioPublicacao: data.inicioPublicacao,
          fimPublicacao: data.fimPublicacao,
          anexo: data.caminhoAnexo
        }

        this.dialogVer = true;
      })
    },

    deletePublicação() {
      api.deleteNoticia(this.noticia.id).then((r) => {
        this.$root.vtoast.show({
          status: 'success',
          text: 'Noticia deletada com sucesso.',
          title: 'SUCESSO!'
        })
      })
      this.dialogDelete = false
      this.receberNoticias()
      this.noticia = {}
    },

    editPublicação(item) {
      api.getNoticiaId(item.id).then(({ data }) => {
        this.noticiaVer = data
      })
      this.dialogEditar = true
    },

    abrirFiltro() {
      this.keyDialogFiltro++;
      this.filtro = { tipo: '', exibicao: '' };
      this.dialogFiltro = true;
      this.receberTiposNoticias();
    },

    filtoNoticia() {
      if (!this.filtro.tipo) {
        this.receberNoticias()
        this.dialogFiltro = false;
        this.filtroAtivo = false;
        return;
      }

      api
        .getNoticiasFiltro(this.filtro, this.pagination)
        .then(({ data }) => {
          this.items = data.content;
          this.pagination.total = data.totalPages;
          this.dialogFiltro = false;
          this.filtroAtivo = true;
        });
    },

    fecharFiltro() {
      this.keyDialogFiltro++;
      this.dialogFiltro = false;
    },

    limparFiltro() {
      this.filtroAtivo = false;
      this.receberNoticias();
    },

    receberTiposNoticias() {
      api.getTipoNoticias().then(({ data }) => {
        this.tiposNoticias = data
      })
    },

    async page(page) {
      this.loading = true
      this.pagination.page = page - 1
      api.getAllNoticias(this.pagination).then(({ data }) => {
        this.items = data.content
        this.pagination.total = data.totalPages
      })
      this.loading = false
    },

    receberNoticias() {
      api.getAllNoticias(this.pagination).then(({ data }) => {
        this.items = data.content
        this.pagination.total = data.totalPages
        this.dialogEditar = false
        this.AdicionarPublicacao = false
      })
    },

    loadURL(link) {
      if (link) {
        const youtubeEmbedTemplate = 'https://www.youtube.com/embed/'
        const url = link.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
        const YId = url[2] ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0];

        const topOfQueue = youtubeEmbedTemplate.concat(YId)
        this.youtubeURL = ''
        return topOfQueue
      }
    }
  },
  created() {
    this.receberNoticias()
  }
}
</script>

<style scoped>
  .container-imagem-slide {
    max-width: 860px;
    max-height: 320px;
  }

  .container-imagem-slide img {
    width: 100%;
    height: 100%;
  }
</style>
