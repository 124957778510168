<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex>
        <v-card class="elevation-1 mx-auto pa-4 mt-15" width="700">
          <v-card-title>Trocar senha</v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-card-text>
            <v-form>
              <v-row>
                <v-col offset-sm="12" md="10" class="mx-auto">
                  <h2 class="text-h5 mb-5"></h2>
                  <FormField :fields="fields" />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <ButtonMain
              @click.native="submit()"
              text="redefinir"
              class="mx-auto primary"
            ></ButtonMain>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha'
import FormField from '@/components/atoms/forms/FormField.vue'
import InputValidacaoSenha from '@/components/atoms/fields/InputValidacaoSenha.vue'
import api from '@/services/api'
import ButtonMain from '@/components/atoms/buttons/ButtonMain.vue'
import { validadoresSenha } from '../mixins/data/validadoresSenha.js'

export default {
  components: {
    FormField,
    VueRecaptcha,
    ButtonMain,
    InputValidacaoSenha
  },

  mixins: [ validadoresSenha ],

  data() {
    return {
      verifique: false,
      confirmPassword: '',
      confirmPasswordRules: [
        (value) =>
          value === this.form.senha || 'As senhas precisam estar iguais.'
      ],
      form: {
        cpf: null,
        email: null,
        telefone: null,
        senha: null
      },
      fields: {}
    }
  },

  mounted() {
    this.fields = {
      senha: {
        field: 'InputValidacaoSenha',
        label: 'Senha',
        valueField: null,
        exibirValidacao: false,
        type: 'password',
        placeholder: 'Senha',
        cols: 12,
        rules: this.REGRAS_SENHA,
        validadores: this.CONDICOES_SENHA
      },

      confirmarSenha: {
        field: 'InputField',
        label: 'Confirmar senha',
        valueField: null,
        type: 'password',
        placeholder: 'Confirmar senha',
        cols: 12,
        iconeSenha: 'mdi-eye-outline',
        rules: [
          (value) => !!value || 'Campo obrigatório',
          (value) => value === this.fields.senha.value || 'As senhas não são iguais',
        ]
      }
    };
  },

  methods: {
    submit() {
      this.senha = this.fields.senha.value
      if (
        this.fields.senha.value === this.fields.confirmarSenha.value &&
        this.fields.senha.value.length >= 8
      ) {
        api
          .putMudarSenha(this.$store.getters['auth/user'].usuario, this.senha)
          .then((res) => {
            this.form = {}
            this.$root.vtoast.show({
              status: 'success',
              title: 'Sucesso!',
              text: 'senha redefinida com sucesso!',
              time: 2000
            })
          })
          .catch((data) => {
            this.$root.vtoast.show({
              status: 'error',
              title: 'Atenção!',
              text: data.mensagemUsuario
                ? data.mensagemUsuario
                : 'dados Invalidos.',
              time: 5000
            })
          })
      } else {
        this.$root.vtoast.show({
          status: 'error',
          title: 'Atenção!',
          text: 'Dados incorretos.',
          time: 5000
        })
      }
    }
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.form.senha === this.confirmPassword || 'Password must match'
    }
  },
}
</script>

<style></style>
