<template>
  <v-container>
    <div>
      <v-col>
        <v-row>
          <v-col cols="12" md="6">
            <v-autocomplete
              @click:clear="limpar()"
              clearable
              disabled
              hide-details="auto"
              outlined
              v-model="tipoNoticia"
              item-text="descricao"
              item-value="id"
              :items="tiposNoticias"
              label="Tipo de Publicação*"
            ></v-autocomplete
          ></v-col>
          <DateField
            :iniciarData="false"
            v-if="tipoNoticia"
            :cols="3"
            :dateFormatted="dataIncial"
            :date="dataIncial"
            @dateSelected="dataIncial = $event"
            label="Inicio da publicação"
          />

          <DateField
            :iniciarData="false"
            v-if="tipoNoticia"
            :cols="3"
            :dateFormatted="dataFinal"
            :date="dataFinal"
            @dateSelected="dataFinal = $event"
            label="Fim da publicação*"
          />
        </v-row>
      </v-col>
      <div v-if="tipoNoticia">
        <InputField
          v-if="tipoNoticia === 5"
          :rules="tituloRules"
          label="Titulo*"
          :valueField="title"
          @update:value="title = $event"
        />
        <FileInput
          v-if="tipoNoticia !== 3"
          icon="mdi-camera"
          accept="image/png, image/jpeg"
          label="Inserir imagem*"
          :rules="rules"
          @changeFile="imagem = $event"
        />
        <span class="ml-5">
          {{ msgImagem }}
        </span>
        <FileInput
          v-if="tipoNoticia === 5"
          icon="mdi-file"
          accept="application/pdf"
          label="Inserir PDF*"
          :rules="rulesPdf"
          @changeFile="pdf = $event"
        />
        <InputField
          v-if="tipoNoticia !== 2 && tipoNoticia !== 6 && tipoNoticia !== 5"
          label="Link da publicação*"
          :valueField="link"
          :rules="linkRules"
          @update:value="link = $event"
        />

        <InputField
          v-if="tipoNoticia === 2 || tipoNoticia === 6"
          :rules="tituloRules"
          label="Titulo*"
          :valueField="title"
          @update:value="title = $event"
        />
        <v-col>
          <vue-editor
            v-if="tipoNoticia === 2 || tipoNoticia === 6"
            :rules="conteudoRules"
            v-model="content"
            :editor-toolbar="customToolbar"
          />
          <div class="my-4">
            <ButtonMain
              @click.native="
                dialogCard = true
                loadURL()
              "
              :success="true"
              text="Visualizar"
            />
            <ButtonMain
              @click.native="confirmarPublicação = true"
              class="mx-2"
              :primary="true"
              text="Salvar"
            />
          </div>
        </v-col>
      </div>
    </div>
    <DialogCard
      :show="dialogCard"
      :key="keyModal"
      :title="title"
      :img="imagem"
      @close="closeModal"
    >
      <template v-slot:body>
        <div v-html="titleC"></div>
      </template>
      <template v-slot:imagem>
        <h2 v-if="tipoNoticia === 1" class="mb-5">SLIDE SHOW</h2>
        <h2 v-if="tipoNoticia === 3" class="mb-5">GALERIA VIDEOS</h2>
        <h2 v-if="tipoNoticia === 4" class="mb-5">GALERIA AVISOS</h2>
        <div class="text-center">
          <iframe
            v-if="tipoNoticia === 3"
            width="820"
            height="400"
            :src="result"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          >
          </iframe>

          <img v-if="imagem && tipoNoticia !== 3" :src="imagem" height="337" />
        </div>
      </template>
    </DialogCard>
    <v-dialog v-model="confirmarPublicação" width="600">
      <v-card class="overflow-hidden">
        <v-toolbar flat>
          <v-toolbar-title class="ml-2 pt-5">
            <span class="text-title-4"
              >As informações, imagens e links serão publicados no portal<br />
              CAAMA.ORG.BR conforme inseridos no sistema.</span
            >
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="mt-5">
          <span class="text-subtitle-1"> Deseja continuar com a edição?</span>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn @click="confirmarPublicação = false" color="red darken-1" text
            >Cancelar</v-btn
          >
          <v-btn
            @click="
              salvarPublicacao()
              confirmarPublicação = false
            "
            color="blue darken-1"
            text
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import api from '@/services/api'
import InputField from '@/components/atoms/fields/InputField.vue'
import SelectField from '@/components/atoms/fields/SelectField.vue'
import { VueEditor } from 'vue2-editor'
import FileInput from '../../components/atoms/fields/FileInput.vue'
import ButtonMain from '../../components/atoms/buttons/ButtonMain.vue'
import DialogCard from '../../components/molecules/dialogs/DialogCard.vue'
import DateField from '../../components/atoms/fields/DateField.vue'
import globalMethods from '../../mixins/globalMethods.vue'

export default {
  components: {
    VueEditor,
    InputField,
    SelectField,
    FileInput,
    ButtonMain,
    DialogCard,
    DateField
  },

  data() {
    return {
      imagemAdd: false,
      confirmarPublicação: false,
      youtubeURL: '',
      result: '',
      tipoNoticia: '',
      title: '',
      content: '',
      link: '',
      dataIncial: '',
      dataFinal: '',
      dialogCard: false,
      tiposNoticias: [],
      keyModal: 0,
      imagem: '',
      msgedidata: '',
      pdf: '',
      customToolbar: [
        [
          {
            header: [false, 1, 2, 3, 4, 5, 6]
          }
        ],
        ['bold', 'italic', 'underline'],
        ['italic'],
        [
          {
            align: ''
          },
          {
            align: 'center'
          },
          {
            align: 'right'
          }
        ],
        [
          {
            list: 'ordered'
          },
          {
            list: 'bullet'
          },
          {
            list: 'check'
          }
        ],
        [
          {
            background: []
          },
          {
            color: []
          }
        ],
        ['link'],
        ['strike'],
        ['underline'],
        ['clean']
      ],
      rules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          'O arquivo selecionado deve ser menor que 2 MB!' ||
          value.size > 2000 ||
          'O arquivo selecionado é muito pequeno'
      ],
      rulesPdf: [
        (value) =>
          !value ||
          value.size < 6000000 ||
          'O arquivo selecionado deve ser menor que 6 MB!',
        (value) => !!value || 'O conteudo do pdf é obrigatorio'
      ],
      linkRules: [
        (v) => !!v || 'O link é obrigatorio',
        (v) => v.length >= 20 || 'O link deve ter mais de 20 caracteres'
      ],
      tituloRules: [(v) => !!v || 'O Titulo da noticia é obrigatorio'],
      conteudoRules: [
        (v) => !!v || 'O conteudo da noticia é obrigatorio',
        (v) =>
          v.length >= 20 ||
          'O conteudo da noticia deve ter mais de 50 caracteres'
      ]
    }
  },
  mixins: [globalMethods],
  props: ['formatarCampo'],
  computed: {
    titleC() {
      return `${this.content}`
    },
    msgImagem() {
      if (this.tipoNoticia === 1) {
        return 'O tamanho recomendado para a imagem é de 863px de largura X 348px de altura'
      }
      if (this.tipoNoticia === 2 || this.tipoNoticia === 6) {
        return 'O tamanho recomendado para a imagem é de 1000px de largura X 650px de altura'
      }
      if (this.tipoNoticia === 4) {
        return 'O tamanho recomendado para a imagem é de 1000px de largura X 650px de altura'
      }
      if (this.tipoNoticia === 5) {
        return 'O tamanho recomendado para a imagem é de 1000px de largura X 650px de altura'
      }
      return ' '
    }
  },
  watch: {
    formatarCampo: function (noticia) {
      this.tipoNoticia = ''
      this.title = ''
      this.content = ''
      this.imagem = null
      this.link = ''
      this.dataIncial = ''
      this.dataFinal = ''
    },
    noticiaEditar: function (noticia) {
      this.tipoNoticia = noticia?.tipoNoticia?.id ? noticia.tipoNoticia.id : ''
      this.title = noticia?.titulo ? noticia.titulo : ''
      this.content = noticia?.texto ? noticia.texto : ''
      this.imagem = noticia?.caminhoArquivo ? noticia.caminhoArquivo : null
      this.link = noticia?.link ? noticia.link : ''
      this.dataIncial = noticia?.inicioPublicacao?.split(' ')[0]
      this.dataFinal = noticia?.fimPublicacao?.split(' ')[0]
    }
  },
  props: {
    formatarCampo: {
      type: Boolean,
      required: true
    },
    noticiaEditar: {
      type: Object,
      required: true
    }
  },
  methods: {
    closeModal() {
      this.dialogCard = false
      this.keyModal++
    },

    limpar() {
      this.dataIncial = '';
      this.title = '';
      this.content = '';
      this.link = '';
      this.imagem = '';
      this.dataFinal = '';
    },

    guardaTipoNoticia(tipos) {
      return tipos.some(tipo => tipo === this.tipoNoticia);
    },

    salvarPublicacao() {
      if (this.tipoNoticia === 2 || this.tipoNoticia === 6) {
        this.msgedidata = this.content
          .replace(/<span style="color: rgb\(0, 0, 0\);">(.*?)<\/span>/gi, '$1')
          .replace(/<span style="color: black;">(.*?)<\/span>/gi, '$1')
      }

      if(this.dataIncial && (!this.dataFinal || this.dataIncial <= this.dataFinal)){
        if(!this.imagem && this.guardaTipoNoticia([1, 2, 4])) {
          this.$root.vtoast.show({
            status: 'error',
            title: 'Erro!',
            text: 'Para continuar faça o upload da imagem',
          });

          return;
        }

        if(!this.link && this.guardaTipoNoticia([1, 3, 4])) {
          this.$root.vtoast.show({
            status: 'error',
            title: 'Erro!',
            text: 'Para continuar informe o link para a publicação',
          });

          return;
        }

        if ((this.tipoNoticia === 2 || this.tipoNoticia === 6) && !this.title) {
          this.$root.vtoast.show({
            status: 'error',
            title: 'Erro!',
            text: 'Para continuar informe um titulo para a publicação',
          });

          return;
        }

        if ((this.tipoNoticia === 2 || this.tipoNoticia === 6) && this.title.length > 100) {
          this.$root.vtoast.show({
            status: 'error',
            title: 'Erro!',
            text: 'Número de caracteres do título excede o limite maximo que é de 100',
          });

          return;
        }

        if ((this.tipoNoticia === 2 || this.tipoNoticia === 6) && !this.content) {
          this.$root.vtoast.show({
            status: 'error',
            title: 'Erro!',
            text: 'Para continuar insira um conteudo para a publicação',
          });

          return;
        }

        if ((this.tipoNoticia === 2 || this.tipoNoticia === 6) && this.msgedidata.length > 8000) {
          this.$root.vtoast.show({
            status: 'error',
            title: 'Erro!',
            text: 'Número de caracteres excede o limite maximo que é de 8000',
          });

          return;
        }

        let caminhoArquivo = ''
        let extensaoarquivo = ''
        let base64 = ''
        let base64Pdf = ''

        if (this.imagem !== this.noticiaEditar.caminhoArquivo) {
          caminhoArquivo = this.imagem.split(';')[0]
          extensaoarquivo = caminhoArquivo.replace('data:image/', '')
          base64 = this.imagem.replace('data:', '').replace(/^.+,/, '')
        }
        if (this.pdf !== this.noticiaEditar.anexo) {
          base64Pdf = this.pdf.replace('data:application/pdf;base64,', '')
        }

        const data = {
          link: this.guardaTipoNoticia([1, 3, 4]) ? this.link.trim() : null,
          extensao: this.guardaTipoNoticia([1, 2, 4, 5, 6]) ? extensaoarquivo : null,
          inicioPublicacao: `${this.dataIncial} 00:01`,
          fimPublicacao: this.dataFinal ? `${this.dataFinal} 23:59` : null,
          anexo: this.tipoNoticia === 5 ? base64Pdf : null,
          foto: this.guardaTipoNoticia([1, 2, 4, 5, 6]) ? base64 : null,
          texto: (this.tipoNoticia === 2 || this.tipoNoticia === 6) ? this.msgedidata : null,
          tipoNoticia: { id: this.tipoNoticia },
          titulo: this.guardaTipoNoticia([2, 5, 6]) ? this.title : null
        }

        api
          .puttNoticiaId(this.noticiaEditar.id, data)
          .then(() => {
            this.$emit('atualizar')
            this.$root.vtoast.show({
              status: 'success',
              text: 'Noticia publicada com sucesso.',
              title: 'SUCESSO!'
            })
          })
          .catch(({ response }) => {
            this.$root.vtoast.show({
              status: 'error',
              title: 'Erro!',
              text: response.data.mensagemUsuario,
            })
          });

      } else {
        this.$root.vtoast.show({
          status: 'error',
          title: 'Erro!',
          text:
            this.dataIncial > this.dataFinal
              ? 'data de início não pode ser maior que a data final'
              : 'Para continuar informe a data de inicio da publicação',
        })
      }
    },

    loadURL() {
      const youtubeEmbedTemplate = 'https://www.youtube.com/embed/'
      const url = this.link.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
      const YId = url[2] ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0]

      const topOfQueue = youtubeEmbedTemplate.concat(YId)
      this.result = topOfQueue
      this.youtubeURL = ''
    }
  },
  created() {
    api.getTipoNoticias().then(({ data }) => {
      this.tiposNoticias = data
    })
  }
}
</script>
